<template>
  <div>
    <b-row v-if="$auth.user().funnels.includes('person.create')">
      <b-col>
        <b-alert show class="p-2" style="font-size: 1.2rem">
          {{ $t("person.tour.cta") }}
        </b-alert>
      </b-col>
    </b-row>
    <go-back :title="$t('people.table.title')">
      <b-button
        variant="primary"
        block
        :to="{ name: 'person-create' }"
        v-if="$auth.check('person-create')"
      >
        <feather-icon icon="PlusIcon" class="mr-50"/>
        {{ $t("person.actions.create") }}
      </b-button>
    </go-back>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t("people.table.title") }}</b-card-title>
      </b-card-header>
      <data-table
        :fetchData="fetchData"
        :columns="columns"
        :forceRefresh="refreshTable"
        v-if="$auth.check('person-list')"
      >
        <template slot="row" slot-scope="props">
          <!-- Column: Name -->
          <user v-if="props.column.field === 'name'" :user="props.row" />

          <!-- Column: Tags -->
          <tag-box
            v-else-if="props.column.field === 'tags'"
            :tags="props.row.tags"
          />

          <span v-else-if="props.column.field === 'created_at'">
            {{ $d(new Date(props.row.created_at * 1000), "long", "en") }}
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <action-button
              :title="$t('person.actions.show')"
              icon="EyeIcon"
              :to="{ name: 'person-show', params: { id: props.row.id } }"
              permissions="person-show"
            />
            <action-button
              :title="$t('person.actions.update')"
              icon="Edit2Icon"
              :to="{ name: 'person-edit', params: { id: props.row.id } }"
              permissions="person-update"
            />
            <action-button
              :title="$t('common.actions.delete')"
              icon="XIcon"
              @click="() => deletePerson(props.row.id)"
              permissions="person-destroy"
            />
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </data-table>
    </b-card>
  </div>
</template>

<script>
import { destroy, index } from "@/api/people.api";
import DataTable from "@/components/DataTable/DataTable.vue";
import User from "@/components/User.vue";
import TagBox from "@/components/TagBox.vue";
import GoBack from "@/components/Common/GoBack.vue";
import ActionButton from "@/components/DataTable/ActionButton.vue";

export default {
  components: { DataTable, ActionButton, User, TagBox, GoBack },
  name: "PeopleList",
  data() {
    return {
      columns: [
        {
          label: this.$t("fields.name"),
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: this.$t("common.actions.filter"),
          },
        },
        {
          label: this.$t("fields.organization_name"),
          field: "organization.name",
          guard_name: "management",
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: this.$t("common.actions.filter"),
          },
        },
        {
          label: this.$t("fields.reference"),
          field: "reference",
          filterOptions: {
            enabled: true,
            placeholder: this.$t("common.actions.filter"),
          },
        },
        {
          label: this.$t("fields.tags"),
          field: "tags",
          sortable: false,
        },
        {
          label: this.$t("fields.created_at"),
          field: "created_at",
          sortable: true,
        },
        {
          label: "Actions",
          field: "action",
          width: "140px",
          tdClass: "text-center",
          sortable: false,
        },
      ],
      refreshTable: false,
    };
  },
  methods: {
    async fetchData(filters) {
      return await index(filters);
    },
    async deletePerson(id) {
      this.$swal({
        title: this.$t("person.messages.destroy-title"),
        text: this.$t("person.messages.delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("common.actions.confirm"),
        cancelButtonText: this.$t("subscription.actions.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (!result.value) return;
        await destroy(id);
        this.refreshTable = !this.refreshTable;
      });
    },
  },
};
</script>

<style>
</style>